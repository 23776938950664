<template>
    <div class="set-up-container">
        <div class="set-up-header">
            <h4 class="set-up-title">Game Lobby</h4>
            <v-btn class="close-button" @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="divider" />
        <div class="form-container">
            <BoldFormField title="Share Link">
                <v-text-field
                    class="link-text-field"
                    :value="roomUrl"
                    outlined
                    dense
                    hide-details
                    readonly
                    :append-icon="copyIcon"
                    @click:append="updateClickedCopy"
                />
            </BoldFormField>
        </div>
        <div class="divider" />
        <div class="form-container">
            <BoldFormField title="Your Name">
                <v-text-field
                    id="inputPlayerName"
                    class="name-text-field"
                    outlined
                    dense
                    hide-details
                    placeholder="Player 1"
                    :value="name"
                    @input="setPlayerName"
                    maxlength="20"
                    autofocus
                />
            </BoldFormField>
            <BoldFormField title="Players">
                <v-chip-group column>
                    <v-chip
                        v-for="(name, i) in players"
                        :key="'player' + i"
                        color="#424242"
                        dark
                    >
                        <v-avatar
                            :color="
                                [
                                    '#E91B0C',
                                    '#5ccc00',
                                    '#e0ca00',
                                    '#FF1F69',
                                    '#00b8b8',
                                ][i % 5]
                            "
                            left
                        >
                            {{ name.slice(0, 1).toUpperCase() }}
                        </v-avatar>
                        {{ name }}
                    </v-chip>
                </v-chip-group>
            </BoldFormField>
        </div>
        <div class="settings-container">
            <div v-if="playerNumber === 1" class="btn-footer">
                <v-btn
                    id="btnStart"
                    class="next-button"
                    @click="handleNext()"
                    :disabled="players.length < 2 || !canNext"
                >
                    <h4>Start</h4>
                </v-btn>
            </div>
            <p v-if="playerNumber !== 1" class="footer-text">
                Waiting for host to start the game...
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CardRoomMixin from './mixins/CardRoomMixin';
import { BoldFormField } from '@/components/shared';

export default {
    props: {
        close: {
            type: Function,
            required: true
        },
        handleNext: {
            type: Function,
            required: true
        }
    },
    components: {
        BoldFormField,
    },
    data() {
        return {
            copyIcon: 'mdi-content-copy',
        };
    },
    mixins: [CardRoomMixin],
    computed: {
        ...mapState('settingsStore', [
            'playerNumber',
            'roomName',
            'players',
            'name',
            'invalidName',
        ]),
        ...mapState({
            streamerMode: (state) => state.homeStore.streamerMode,
        }),
        roomUrl() {
            return window.origin + '/room/' + this.roomName;
        },
        canNext() {
            return !this.players.some((name) => name === '');
        }
    },
    methods: {
        ...mapActions('settingsStore', ['setPlayerName', 'createRoom']),
        updateClickedCopy() {
            this.copyIcon = 'mdi-check';
            this.$copyText(this.roomUrl, this.$refs.roomUrl);
        },
    },
};
</script>

<style scoped>
#card-title {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.9;
}
h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 500;
}
.form-container {
    padding: 1rem;
}
.link-text-field {
    width: 90%;
}
.name-text-field {
    width: 50%;
}
.footer-text {
    text-align: center;
}
.btn-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}
</style>
