<template>
    <div>
        <v-app-bar 
            class="header" 
            height="80"
            color="transparent"
            v-click-outside="onOutsideClick"
        >
            <div class="flex-grow-1" />
            <v-btn
                v-show="user && user.email"
                icon
                class="header__user-avatar-btn"
                @click="avatarMenuVisible = !avatarMenuVisible"
            >
                <v-avatar class="header__user-avatar" v-bind:class="(user.bmacTip)?'supporter':''">
                    <v-icon style="font-size: 42px" v-if="!user.photoURL">mdi-account</v-icon>
                    <img v-if="user.photoURL" :src="user.photoURL">
                </v-avatar>
            </v-btn>
            <nav 
                class="header__nav"
                :class="{ visible: avatarMenuVisible }"
            >
                <v-list-item class="nav-list-item">
                    <v-list-item-content>
                        <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="user.bmacTip">Supporter since {{ user.bmacTip.supported_on }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-btn @click="onLogOutClicked">Logout</v-btn>
            </nav>
            <v-app-bar-nav-icon
                class="header__nav-icon"
                @click="menuVisible = !menuVisible"
            ></v-app-bar-nav-icon>
            <nav 
                class="header__nav"
                :class="{ visible: menuVisible }"
            >
                <v-btn id="homeBtn" text link to="/">
                    HOME
                </v-btn>
                <v-btn id="historyBtn" text link to="/history">
                    {{ $t('Home.historyBtn') }}
                </v-btn>
                <v-btn id="aboutBtn" text link to="/about">
                    ABOUT
                </v-btn>
                <!-- Language Selector -->
                <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn id="languageBtn" icon v-on="on">
                            <v-icon size="30"> mdi-translate </v-icon>
                        </v-btn>
                    </template>
                    <v-list id="menuLanguage">
                        <v-list-item
                            v-for="(language, index) in languages"
                            :key="index"
                            @click="switchLanguage(language.value)"
                        >
                            <v-list-item-title>
                                {{ language.text }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </nav>
        </v-app-bar>
        <HeaderAlert />
    </div>
</template>
<script>
import { languages, RTL_LANGUAGES } from '../../lang';
import { mapActions, mapState } from 'vuex';
import HeaderAlert from './HeaderAlert.vue';
import { FIREBASE_UI_CONFIG } from '../../constants';
import * as firebaseui from 'firebaseui';

export default {
    components: {
        HeaderAlert,
    },
    data() {
        return {
            aboutDialog: false,
            languages,
            menuVisible: false,
            avatarMenuVisible: false
        };
    },
    computed: {
        ...mapState({
            streamerMode: (state) => state.homeStore.streamerMode,
            user: (state) => state.homeStore.user,
        }),
        demoMode() {
            return !!process.env.VUE_APP_DEMO_MODE;
        },
        darkTheme() {
          return this.$vuetify.theme.dark;
        }
    },
    methods: {
        ...mapActions(['setStreamerMode', 'logOut']),
        changeStreamerMode(streamerMode) {
            this.setStreamerMode(streamerMode);
        },
        switchLanguage(language) {
            this.$root.$i18n.locale = language;
            this.$vuetify.lang.current = language;
            this.$vuetify.rtl = RTL_LANGUAGES.includes(language);
            this.saveLanguage(language);
        },
        saveLanguage(language) {
            localStorage.setItem('language', language);
        },
        changeTheme(dark) {
          this.$vuetify.theme.dark = dark;
          localStorage.setItem('darkTheme', dark);
        },
        onOutsideClick() {
            if (this.menuVisible) this.menuVisible = false;
            if (this.avatarMenuVisible) this.avatarMenuVisible = false;
        },
        onLogOutClicked() {
            this.logOut();
            this.avatarMenuVisible = false;
            const ui = firebaseui.auth.AuthUI.getInstance();
            ui.reset();
            ui.start('#firebaseui-auth-container', FIREBASE_UI_CONFIG);
        }
    },
};
</script>
<style lang="scss" scoped>
.header {
    z-index: 1;
    box-shadow: none !important;
    .header__nav {
        &:not(.visible) {
            display: none;
        }
        position: absolute;
        top: 6.2rem;
        right: 1rem;
        background: white;
        padding: 1rem 0.5rem;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%);
        border-bottom-left-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        max-width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow-y: auto;
        border-radius: 0.5rem;
        .header__nav__btns {
            margin: 0;
        }
    }
    .header__nav-icon {
        background-color: white;
    }
    .header__user-avatar-btn {
        margin-right: 1rem;
    }
    .header__user-avatar {
        border: 2px solid white;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
        }
    }
    .supporter {
        border: 4px solid gold;
    }
    .nav-list-item {
        max-width: 200px;
    }
    .header__nav__btns {
        display: flex;
        align-items: center;        
        & > div {
            margin: 0 1.5rem;
        }
    }
    .theme--light .header__nav__btns .v-btn{
        color: rgba(0, 0, 0, 0.87);
        margin: 0.25rem;
    }
    .theme--dark .header__nav__btns .v-btn{
        color: rgba(196, 110, 110, 0.87);
        margin: 0.25rem;
     }
    .v-btn {
        a {
            text-decoration: none;
            color: initial;
        }
        font-size: 1.2rem;
    }
}
</style>
