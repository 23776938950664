<template>
    <v-container class="time-picker">
        <v-row class="slider">
            <!-- timeLimit in seconds -->
            <v-slider
                v-model="timeLimit"
                max="600"
                min="0"
                step="30"
                ticks
                hide-details
                @change="changeAll"
            />
        </v-row>
        <v-row class="no-margin">
            <p
                v-if="timeLimit === 0"
                class="time-title"
            >
                {{ $t('CardRoomTime.infinite') }}
            </p>
            <p
                v-else-if="timeLimit < 60"
                class="time-title"
            >
                {{ timeLimit }} seconds
            </p>
            <p
                v-else
                class="time-title"
            >
                {{ timeLimit / 60 }} min
            </p>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            max: 0,
            timeLimit: this.value,
        };
    },
    methods: {
        changeAll(time) {
            this.$emit('input', time);
        },
    },
};
</script>
<style lang="scss" scoped>
.slider {
    width: 50%;
    max-width: 250px;
}
.time-title {
    text-align: center;
    font-size: 1rem;
    line-height: 3.5rem;
    margin-bottom: 0;
    margin-left: 2rem;
}
.infinite--text {
    text-align: center;
    font-size: 1rem;
    width: 100%;
    line-height: 3.5rem;
}
.time-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    padding: 0 4px !important;
}
.no-margin {
    margin: 0 !important;
}
</style>
