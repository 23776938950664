<template>
    <div class="count-field-container">
        <div class="input-text">{{ this.text }}</div>
        <v-btn class="round-btn with-margin" @click="this.decrement">
            <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn class="round-btn" @click="this.increment">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    increment: {
      type: Function,
      required: true
    },
    decrement: {
      type: Function,
      required: true
    }
  }
};

</script>

<style lang="scss">
  .count-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .with-margin {
    margin: 0em 1em;
  }
  .input-text {
    font-size: 1em;
    margin: 0px
  }
  .round-btn {
    height: 2em !important;
    width: 2em !important;
    border-radius: 100%;
    border: solid;
    border-width: 2px;
    border-color: #909090 !important;
    box-shadow: none !important;
    color: #909090 !important;
    min-width: 0 !important;
    background-color: white !important;
    padding: 0 !important;
  }
</style>