import firebase from 'firebase/app';
import 'firebase/auth';

export const GAME_MODE = {
    CLASSIC: 'classic',
    COUNTRY: 'country',
    CUSTOM_AREA: 'custom_area',
};

export const SCORE_MODE = {
    NORMAL: 'normal',
    TIME: 'time',
};

export const AREA_MODE = {
    NOMINATIM: 'nominatim',
    POLYGON: 'polygon',
};

export const STROKE_COLORS = [
   '#b71c1c',
   '#33691e',
   '#f57f17',
   '#880e4f',
   '#01579b',
];

export const PLAYER_MODES = {
    SINGLE_PLAYER: 'single_player',
    MULTI_PLAYER: 'multi_player'
};

export const GAME_MODES = [
    {
      text: 'Single Player',
      value: PLAYER_MODES.SINGLE_PLAYER,
    },
    {
      text: 'Multi Player',
      value: PLAYER_MODES.MULTI_PLAYER
    }
];

export const SCORE_MODES = [
    {
      text: 'Normal',
      value: SCORE_MODE.NORMAL,
    },
    {
      text: 'Time',
      value: SCORE_MODE.TIME,
    }
];

export const DAILY_MAPS_QUOTA = 'MAPS_QUOTA';

export const FIREBASE_UI_CONFIG = {
  callbacks: {
      signInSuccessWithAuthResult: function() {
          return false; // false causes automatic redirect. true would trigger redirect to custom URL
      },
  },
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      }
  ],
};
