<template>
    <div class="item-container">
        <b class="bold-title">
            {{ title }}
        </b>
        <slot />
    </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .item-container {
    margin: 0 32px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 600px) {
    .item-container {
      flex-direction: row;
      align-items: center;
      padding: 0 8px;
      margin-bottom: 16px;
      width: 100%;
    }
    .bold-title {
      width: 50%;
      text-align: end;
      padding-right: 32px;
    }
  }
</style>