<template>
    <div class="set-up-container">
        <div class="set-up-header">
            <h4 class="set-up-title">Game Set Up</h4>
            <v-btn class="close-button" @click="this.close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="divider" />
        <div class="settings-container">
            <h3 class="section-header">Settings</h3>
            <div class="settings-form">
                <FormField title="Game Mode">
                    <v-select
                        class="dropdown extra-margin"
                        :items="modes"
                        v-model="selectedMode"
                        variant="underlined"
                    />
                </FormField>
                <FormField title="Rounds">
                    <CountField 
                        :text="this.rounds.toString()"
                        :increment="incrementRounds"
                        :decrement="decrementRounds"
                    />
                </FormField>
                <FormField title="Time Limit">
                    <TimePicker
                        class="time-picker-input"
                        :value="timeLimit"
                        @input="(time) => setTimeLimit(time)"
                    />
                </FormField>
                <FormField title="Scoring Mode">
                    <v-select
                        class="dropdown extra-margin"
                        :items="scoringModes"
                        v-model="selectedScoringMode"
                        variant="underlined"
                    />
                </FormField>
            </div>
        </div>
        <div class="divider" />
        <div class="controls-container">
            <h3 class="section-header">Controls</h3>
            <div class="controls">
                <CheckBoxField
                    title="Allow Zoom"
                    :value="gameSettings.allowZoom"
                    @input="(allowZoom) => updateGameSettings({ allowZoom })"
                />
                <CheckBoxField
                    title="Allow Move"
                    :value="gameSettings.allowMove"
                    @input="(allowMove) => updateGameSettings({ allowMove })"
                />
                <CheckBoxField
                    title="Allow Pan"
                    :value="gameSettings.allowPan"
                    @input="(allowPan) => updateGameSettings({ allowPan })"
                />
            </div>
        </div>
        <div class="set-up-footer">
            <v-btn class="next-button" @click="onNext()">
                <h4>Next</h4>
            </v-btn>          
        </div>
    </div>
</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';
import { CountField, FormField, CheckBoxField } from '@/components/shared';
import TimePicker from '../shared/TimePicker';
import { GAME_MODES, PLAYER_MODES, SCORE_MODES } from '../../constants';

export default {
  props: {
    updateGameSettings: {
      type: Function,
      required: true
    },
    gameSettings: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    handleNext: {
      type: Function,
      required: true
    }
  },
  components: {
    FormField,
    CountField,
    TimePicker,
    CheckBoxField
  },
  data() {
    return {
        modes: GAME_MODES,
        selectedMode: this.gameSettings.mode,
        scoringModes: SCORE_MODES,
        selectedScoringMode: this.gameSettings.scoringMode,
        rounds: this.gameSettings.rounds,
        timeLimit: this.gameSettings.timeLimit,
        playerModes: PLAYER_MODES,
    };
  },
  methods: {
    incrementRounds() {
        this.rounds++;
        this.rounds = Math.min(10, this.rounds);
      },
      decrementRounds() {
        this.rounds--;
        this.rounds = Math.max(1, this.rounds);
      },
      setTimeLimit(time) {
        this.timeLimit = time;
      },
      onNext() {
        // the controls are already updated on checkbox change
        this.updateGameSettings({
          mode: this.selectedMode,
          rounds: this.rounds,
          timeLimit: this.timeLimit,
          scoringMode: this.selectedScoringMode,
        });
        this.handleNext();
      },
  },
  mounted() {
    amplitude.track('Game set up');
  }
};

</script>

<style lang="scss">
  .set-up-container {
    height: fit-content;
    padding: 16px !important;
    border-radius: 20px;
    margin: 0rem 3rem;
    background-color: white;
    min-width: 40%;
    max-width: 50%;
    overflow: hidden;
  }
  @media (max-width: 600px) {
    .set-up-container {
      max-width: 100%;
      width: calc(100% - 2rem);
      margin: 0rem 2rem;
    }
  }
  .set-up-header {
    align-items: center;
    display: grid;
    margin-bottom: 0.5rem;
  }
  .set-up-title {
    text-align: center;
  }
  .close-button {
    box-shadow: none;
    background-color: white !important;
    min-width: 0px !important;
    width: fit-content;
    padding: 0 0.2rem !important;
  }
  .set-up-title, .close-button {
    grid-column: 1;
    grid-row: 1;
  }
  .divider {
    width: 120%;
    height: 1px;
    background: #B7C1C1;
    position: relative;
    left: -1rem;
  }
  .section-header {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .settings-container {
    margin: 0 0.5rem;
  }
  .extra-margin {
    margin-top: 0.5rem !important;
  }
  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .set-up-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    background-color: white !important;
  }
  .next-button { 
    box-shadow: none;
    background-color: var(--v-primary-base) !important;
    color: white !important;
    padding: 0.2rem 2rem !important;
    border-radius: 45px;
    text-transform: none;
    font-size: 1rem;
  }
</style>