
<template>
    <div class="logo quota-box">
        <v-container class="container">
            <v-row class="row">
                <p class="heading">Today's Free Games Played</p>
            </v-row>
            <v-row class="row margin-top">
                <v-slider
                    v-model="quotaUsedPercentage"
                    min="0"
                    max="100"
                    color="red"
                    track-color="grey"
                    readonly
                    thumb-label="always"
                    :thumb-size="32"
                >
                    <template v-slot:thumb-label="modelValue">
                        {{ modelValue.value }}%
                    </template>
                </v-slider>
            </v-row>
            <v-row v-if="quotaUsedPercentage === 100" class="row no-top-margin">
                <p class="quota-reached-text">Daily free quota reached. GeoGuess relies entirely on tips to pay for the Google Maps API fees.</p>
            </v-row>
            <v-row class="row">
                <div id="bmac-button" />
            </v-row>
        </v-container>
    </div>
</template>

<script>

import postscribe from 'postscribe';
import firebase from 'firebase/app';
import 'firebase/database';
import { DAILY_MAPS_QUOTA } from '../../constants';

const MAX_MAP_LOADS_PER_DAY = 2000;

export default {
  data() {
    return {
      quotaUsedPercentage: 0,
    };
  },
  created() {
    const currentDatePST = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" }).replaceAll('/', '').split(',')[0];
    const refString = currentDatePST + '_' + DAILY_MAPS_QUOTA;
    const dailyMapsQuotaRef = firebase.database().ref(refString);
    dailyMapsQuotaRef.once('value', (snapshot) => {
      const mapLoadsToday = snapshot.child('map_loads').val();
      this.quotaUsedPercentage = Math.min(100, mapLoadsToday / MAX_MAP_LOADS_PER_DAY * 100);
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.onReady();
    });
  },
  methods: {
    onReady() {
      // eslint-disable-next-line
      postscribe('#bmac-button', `<script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="geoguess" data-color="#FF385C" data-emoji="☕" data-font="Poppins" data-text="Tip any amount - Play Unlimited!" data-outline-color="#000000" data-font-color="#ffffff" data-coffee-color="#FFDD00" ><\/script>`);
    }
  }
};

</script>

<style>
.quota-box {
  height: fit-content;
  border-radius: 20px;
  max-width: 50%;
}
.heading {
  font-weight: bold;
  text-align: center;
}
.margin-top {
  margin-top: 32px !important;
}
.bmc-btn-text {
  font-family: 'League Spartan', sans-serif;
  color: white;
  font-size: 2vw;
}
.container {
  padding: 0 12px;
}
.row {
  margin: 0px;
  justify-content: center;
}
.quota-bar {
    background-color: #4caf50;
    position: absolute;
    top: 0;
    left: 0;
}
.quota-reached-text {
  text-align: center;
}
.no-top-margin {
  margin-top: 0px !important;
}
</style>
