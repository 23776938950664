import { render, staticRenderFns } from "./ContentPage.vue?vue&type=template&id=383cb608&scoped=true&"
import script from "./ContentPage.vue?vue&type=script&lang=js&"
export * from "./ContentPage.vue?vue&type=script&lang=js&"
import style0 from "./ContentPage.vue?vue&type=style&index=0&id=383cb608&prod&scoped=true&lang=scss&"
import style1 from "./ContentPage.vue?vue&type=style&index=1&id=383cb608&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "383cb608",
  null
  
)

export default component.exports