var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"set-up-container"},[_c('div',{staticClass:"set-up-header"},[_c('h4',{staticClass:"set-up-title"},[_vm._v("Choose Map")]),_c('v-btn',{staticClass:"close-button",on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"settings-container"},[_c('div',[_c('v-combobox',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"search-bar",attrs:{"items":_vm.items,"search-input":_vm.search,"loading":_vm.isLoading,"placeholder":_vm.$t('Home.searchBar.enterCity'),"append-icon":"mdi-chevron-down","label":"Enter a location","outlined":"","single-line":"","dense":"","hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.loadPlaceGeoJSON},model:{value:(_vm.place),callback:function ($$v) {_vm.place=$$v},expression:"place"}})],1),_c('GmapMap',{ref:"mapRef",staticClass:"map-container",staticStyle:{"width":"100%","height":"400px"},attrs:{"center":{ lat: 10, lng: 10 },"zoom":1,"map-type-id":"roadmap","options":{
                minZoom: 1,
                mapTypeControl: false,
                fullscreenControl: false,
                gestureHandling: 'greedy',
                restriction: {
                    latLngBounds: {
                        north: 85,
                        south: -85,
                        west: -180,
                        east: 180,
                    },
                    strictBounds: false,
                },
            }}}),_c('div',{staticClass:"set-up-footer"},[(this.geoJson)?_c('v-btn',{staticClass:"reset-button",on:{"click":_vm.reset}},[_c('h4',[_vm._v("Reset")])]):_vm._e(),_c('v-btn',{staticClass:"next-button",on:{"click":function($event){return _vm.onNext()}}},[(this.gameMode === _vm.PLAYER_MODES.SINGLE_PLAYER)?_c('h4',[_vm._v("Start")]):_c('h4',[_vm._v("Next")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }