<template>
    <div class="field-container">
        <div class="field-title">
            {{ title }}
        </div>
        <div class="field">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-container {
    display: flex;
    margin-bottom: 1rem;
  }
  .field-title {
    margin-right: 16px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    width: 20%;
  }
  .field {
    flex-grow: 1;
  }
</style>