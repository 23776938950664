<template>
    <ContentPage class="home-page">
        <section class="home-page__main">
            <v-container class="home-page__main__container" fluid>
                <v-layout class="home-page__main__layout">
                    <v-layout class="home-page__main__content">
                        <Logo v-if="state.setUpState === SET_UP_STATES.NOT_STARTED" />
                        <Login v-show="!user.email" /> 
                        <QuotaContainer v-show="user.email && state.setUpState === SET_UP_STATES.NOT_STARTED" />
                        <div v-bind:class="(!user.email)?'overlay':'overlay-clear'">
                            <StartGameMenu
                                v-if="state.setUpState === SET_UP_STATES.NOT_STARTED"
                                :startGameSetUp="startGameSetUp"
                            />
                            <GameSetUp
                                v-else-if="state.setUpState === SET_UP_STATES.GAME_SET_UP"
                                :updateGameSettings="updateGameSettings"
                                :gameSettings="state.gameSettings"
                                :close="closeGameSetUp"
                                :handleNext="handleGameSetUpFinished"
                            />
                            <ChooseMap
                                v-else-if="state.setUpState === SET_UP_STATES.CHOOSE_MAP"
                                :updateGameSettings="updateGameSettings"
                                :gameSettings="state.gameSettings"
                                :close="closeGameSetUp"
                                :handleNext="handleChooseMapFinished"
                            />
                            <GameLobby
                                v-else-if="state.setUpState === SET_UP_STATES.SHARE_ROOM || state.setUpState === SET_UP_STATES.JOIN_ROOM"
                                :close="closeGameSetUp"
                                :handleNext="onStartGame"
                            />
                        </div>
                        <div class="flex-grow-1" />
                    </v-layout>
                </v-layout>
            </v-container>
        </section>
    </ContentPage>
</template>


<script>

import * as amplitude from '@amplitude/analytics-browser';
import ContentPage from '@/components/page/ContentPage';
import Logo from '@/components/Logo';
import GameLobby from '@/components/dialogroom/card/GameLobby';
import StartGameMenu from '../components/home/StartGameMenu';
import Login from '@/components/home/Login';
import GameSetUp from '../components/home/GameSetUp';
import ChooseMap from '../components/home/ChooseMap';
import QuotaContainer from '../components/home/QuotaContainer.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import { GAME_MODE, PLAYER_MODES, SCORE_MODE, FIREBASE_UI_CONFIG } from '../constants';
import { SETTINGS_RESET } from '../store/mutation-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import * as firebaseui from 'firebaseui';
import * as GmapVue from 'gmap-vue';
import Vue from 'vue';

const SET_UP_STATES = {
    NOT_STARTED: 'not_started',
    GAME_SET_UP: 'game_set_up',
    CHOOSE_MAP: 'choose_map',
    JOIN_ROOM: 'join_room',
    SHARE_ROOM: 'share_room',
    STARTED: 'started'
};

export default {
    components: {
        ContentPage,
        Logo,
        StartGameMenu,
        Login,
        GameSetUp,
        ChooseMap,
        GameLobby,
        QuotaContainer
    },
    props: {
        dialogCustomOpen: Boolean,
    },
    data() {
        return {
            state: {
                setUpState: SET_UP_STATES.NOT_STARTED,
                gameSettings: {
                    rounds: 5,
                    mode: PLAYER_MODES.SINGLE_PLAYER,
                    timeLimit: 0,
                    scoringMode: SCORE_MODE.NORMAL,
                    allowZoom: true,
                    allowMove: true,
                    allowPan: true,
                }
            },
        };
    },
    methods: {
        ...mapActions('settingsStore', ['searchRoom', 'startGame', 'updateAndStartGame', 'setGameSettings']),
        ...mapActions(['resetHomeState', 'setUser', 'updateUser']),
        ...mapMutations('settingsStore', {
            resetSettings: SETTINGS_RESET,
        }),

        startGameSetUp(gameMode, rounds) {
            if (this.user && this.user.email) {
                // first reset home store
                this.resetHomeState();
                this.resetSettings();
    
                this.updateGameSettings({ rounds, mode: gameMode });
                this.state.setUpState = SET_UP_STATES.GAME_SET_UP;
            }
        },
        closeGameSetUp() {
            this.state.setUpState = SET_UP_STATES.NOT_STARTED;
        },
        updateGameSettings(gameSettings) {
            this.state.gameSettings = {
                ...this.state.gameSettings,
                ...gameSettings
            };
        },
        handleGameSetUpFinished() {
            this.state.setUpState = SET_UP_STATES.CHOOSE_MAP;
        },
        handleChooseMapFinished() {
            if (this.state.gameSettings.mode === PLAYER_MODES.SINGLE_PLAYER) {
                amplitude.track("Started Game", { gameMode: PLAYER_MODES.SINGLE_PLAYER });
                this.onStartGame();
            } else {
                amplitude.track("Started Game", { gameMode: PLAYER_MODES.MULTI_PLAYER });
                // Create a new room name, and call searchRoom
                const roomName = randomString(8);
                this.searchRoom(roomName);
                this.state.setUpState = SET_UP_STATES.SHARE_ROOM;
            }
        },
        onStartGame() {
            const globalSettings = mapGameSettingsToGlobalGameSettings(this.state.gameSettings);
            this.setGameSettings(globalSettings);
            this.state.setUpState = SET_UP_STATES.STARTED;
            if (this.state.gameSettings.mode === PLAYER_MODES.SINGLE_PLAYER) {
                // Set settings also pushes the user to the game, so don't need to call startGame
                this.updateAndStartGame();
            } else {
                this.startGame();
            }
        },
        // write a function that sorts a list of names
    },
    mounted() {
        amplitude.track('Visited Home Page');

        if (this.$route.params) {
            // If the URL contains a roomName, search for that room
            if (this.$route.params.roomName) {
                this.searchRoom(this.$route.params.roomName);
            }

            if (this.$route.params.partyParams) {
                const params = atob(this.$route.params.partyParams)
                    .split(',')
                    .map((val) => parseFloat(val));
    
                if (params.length >= 12 && params.length % 2 === 0) {
                    const difficulty = params[0];
                    const timeLimitation = params[1];
                    const rounds = new Array((params.length - 2) / 2)
                        .fill(0)
                        .map((_, round) => {
                            const index = (round + 1) * 2;
                            return params.slice(index, index + 2);
                        });
    
                    this.$router.push({
                        name: 'street-view',
                        params: {
                            modeSelected: GAME_MODE.CLASSIC,
                            time: timeLimitation,
                            difficulty: difficulty,
                            roundsPredefined: rounds,
                        },
                    });
                }
            }
        }

        if (this.room) {
            this.state.setUpState = SET_UP_STATES.JOIN_ROOM;
        }

        // Check if user is logged in.
        const auth = firebase.auth();
        const ui = new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', FIREBASE_UI_CONFIG);
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                const userObj = {
                    email: user.email,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                };
                this.setUser(userObj);

                // Get tip and update user
                const [username, domain] = user.email.split('@');
                const emailRef = username + '@' + domain.replace('.', '-dot-');
                const userAccount = firebase.database().ref(emailRef);
                userAccount.on('value', snapshot => {
                    // Once we know if the user is a supporter, init maps with the right API Key
                    const bmacTip = snapshot.child('bmac_tip').val();
                    let googleMapsAPIKey = process.env.VUE_APP_API_KEY;
                    if (bmacTip) {
                        googleMapsAPIKey = process.env.VUE_APP_API_KEY_SUPPORTER;
                        this.updateUser({ bmacTip: bmacTip });
                    }
                    Vue.use(GmapVue, {
                        load: {
                            key: googleMapsAPIKey,
                            language: localStorage.getItem('language'),
                        },
                    });
                });
            }
        });
    },
    created() {
        this.SET_UP_STATES = SET_UP_STATES;
    },
    computed: {
        ...mapState('settingsStore', ['room', 'roomName']),
        ...mapState({
            user: (state) => state.homeStore.user,
        }),
    }
};

function mapGameSettingsToGlobalGameSettings(gameSettings) {
    return {
        time: gameSettings.timeLimit,
        modeSelected: GAME_MODE.CLASSIC,
        zoomControl: gameSettings.allowZoom,
        moveControl: gameSettings.allowMove,
        panControl: gameSettings.allowPan,
        scoreMode: gameSettings.scoringMode,
        nbRoundSelected: gameSettings.rounds,
    };
}

function randomString(length) { 
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
</script>

<style scoped lang="scss">
.v-application--is-rtl .home-page__traveler-img{
    transform: scaleX(-1)

}
.home-page {
    .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
        filter: blur(1px); 
        justify-content: center;
        display: flex;
        pointer-events: none;
    }
    .overlay-clear {
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
    }
    .demo-alert {
        position: absolute;
        z-index: 1;
        width: 100%;
    }
    background-size: cover;
    background-position: center;
    .home-page__main {
        position: relative;
        .home-page__main__container {
            height: 100%;
            font-size: 1.2rem;
            padding: 0;
            margin: 0;
            width: 100%;
            background-size: cover;
            background-position: top;
            .home-page__main__layout {
                height: 100%;
                flex-wrap: nowrap;
                justify-items: end;
                .box {
                    margin: auto;
                    width: 35vw;
                    max-width: 100%;
                }

                .home-page__main__content {
                    width: 100%;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                }
                .home-page__traveler-container {
                    height: auto;
                    width: 100%;
                    max-width: 50vw;
                    display: flex;
                    justify-content: flex-start;
                    .home-page__traveler-img {
                        max-width: 30vw;
                        max-height: 60vh;
                    }
                }
            }
        }
        #btnMaps {
            position: absolute;
            margin: auto;
            bottom: 0.4rem;
            left: 0;
            right: 0;
        }
    }
    
}
@media (max-width: 1300px) and (min-width: 600px) {
    .home-page
        .home-page__main
        .home-page__main__container
        .home-page__main__layout
        .box {
        width: 60vw;
    }
}
@media (max-width: 660px) {
    .home-page {
        padding-bottom: 80px;
        background-color: #ded3af;
        .home-page__main .home-page__main__container {
            .home-page__main__layout {
                flex-direction: column-reverse;

                .box {
                    width: 90vw;
                }
            }
        }
    }
}


@media (max-height: 550px) {
    .home-page
        .home-page__main
        .home-page__main__container
        .home-page__main__layout
        .box {
        margin: 10vh;
    }

    #btnMaps {
        display: none;
    }
}
</style>
