<template>
    <div class="logo start-game-menu">
        <div class="menu-container">
            <MenuItem title="Game Mode">
                <v-select
                    class="dropdown"
                    :items="modes"
                    v-model="selectedMode"
                    variant="underlined"
                />
            </MenuItem>
            <div class="line" />
            <MenuItem title="Rounds">
                <CountField 
                    :text="numRounds.toString()"
                    :increment="incrementRounds"
                    :decrement="decrementRounds"
                />
            </MenuItem>
            <v-btn class="next-button mobile-start" @click="submit">
                <h4>Start</h4>
            </v-btn>  
            <v-btn class="start-button desktop-start" @click="submit">
                <v-img class="arrow" :src="arrowRight"></v-img>
            </v-btn>
        </div>
    </div>
</template>

<script>
import MenuItem from '@/components/shared/MenuItem';
import CountField from '@/components/shared/CountField';
import { PLAYER_MODES, GAME_MODES } from '../../constants';

export default {
  components: {
    MenuItem,
    CountField
  },
  props: {
    startGameSetUp: {
      type: Function,
      required: true
    },
  },
  data() {
        return {
            arrowRight: require('@/assets/arrow-right.svg'),
            modes: GAME_MODES,
            selectedMode: PLAYER_MODES.SINGLE_PLAYER,
            rounds: 5
        };
    },
    computed: {
      numRounds() { return this.rounds; },
    },
    methods: {
      incrementRounds() {
        this.rounds++;
        this.rounds = Math.min(10, this.rounds);
      },
      decrementRounds() {
        this.rounds--;
        this.rounds = Math.max(1, this.rounds);
      },
      submit() {
        this.startGameSetUp(this.selectedMode, this.rounds);
      }
    }
};
</script>


<style lang="scss">
  .dropdown {
    padding-top: 0px !important;
    width: 150px;
  }
  .start-button {
    border-radius: 100%;
    min-width: 0px !important;
    height: 72px !important;
    width: 72px !important;
    background-color: var(--primary-color) !important;
    margin-left: 32px;
  }
  .v-text-field__details {
    height: 0px !important;
    min-height: 0px !important;
  }
  .start-game-menu {
    height: fit-content;
    padding: 16px !important;
  }
  .menu-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 600px) {
    .menu-container {
      flex-direction: column;
    }
    .start-game-menu {
      border-radius: 16px;
      width: calc(100% - 2rem);
    }
    .desktop-start {
      display: none;
    }
  }
  @media not all and (max-width: 600px) {
    .menu-container {
      flex-direction: row;
    }
    .line {
      width: 1px;
      background: #B7C1C1;
      margin: 0 16px;
      height: 80px;
    }
    .mobile-start {
      display: none;
    }
  }
  .arrow {
    height: 40px !important;
    width: 40px !important;
  }
</style>

<style lang="scss" scoped>
  .count-field-container {
    margin-top: 8px;
  }
</style>