<template>
    <ContentPage>
        <div class="container">
            <div class="content">
                GeoGuess.io is a fork of the open source <a href="https://github.com/GeoGuess/GeoGuess">GeoGuess</a> project. If you want to host your own GeoGuess game, see <a href="https://geoguess.games/">here</a> for instructions on how to do so. 
                <br /><br />
                This site, however, is meant to give non-engineers a way to play GeoGuess for free. GeoGuess.io survives solely based on tips to cover the google maps API costs, so please consider supporting us if you can. 🙏
            </div>
        </div>
    </ContentPage>
</template>

<script>

import ContentPage from '@/components/page/ContentPage';

export default {
    name: 'HistoryPage',
    components: {
        ContentPage,
    },
};
</script>

<style>
.container{
  width: 100%;
}
</style>
