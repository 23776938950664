<template>
    <div class="set-up-container">
        <div class="set-up-header">
            <h4 class="set-up-title">Choose Map</h4>
            <v-btn class="close-button" @click="handleClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="divider" />
        <div class="settings-container">
            <div>
                <v-combobox
                    v-model="place"
                    :items="items"
                    :search-input.sync="search"
                    :loading="isLoading"
                    :placeholder="$t('Home.searchBar.enterCity')"
                    append-icon="mdi-chevron-down"
                    label="Enter a location"
                    outlined
                    single-line
                    dense
                    hide-details
                    class="search-bar"                    
                    @input="loadPlaceGeoJSON"
                    v-click-outside="clickOutside"
                />
            </div>
            <GmapMap
                ref="mapRef"
                class="map-container"
                :center="{ lat: 10, lng: 10 }"
                :zoom="1"
                map-type-id="roadmap"
                style="width: 100%; height: 400px"
                :options="{
                    minZoom: 1,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'greedy',
                    restriction: {
                        latLngBounds: {
                            north: 85,
                            south: -85,
                            west: -180,
                            east: 180,
                        },
                        strictBounds: false,
                    },
                }"
            />
            <div class="set-up-footer">
                <v-btn 
                    v-if="this.geoJson"
                    class="reset-button"
                    @click="reset"
                >
                    <h4>Reset</h4>
                </v-btn> 
                <v-btn class="next-button" @click="onNext()">
                    <h4 v-if="this.gameMode === PLAYER_MODES.SINGLE_PLAYER">Start</h4>
                    <h4 v-else>Next</h4>
                </v-btn>          
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PLAYER_MODES } from '../../constants';
import * as amplitude from '@amplitude/analytics-browser';

export default {
  props: {
    updateGameSettings: {
      type: Function,
      required: true
    },
    gameSettings: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    handleNext: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      place: '',
      maps: [],
      isLoading: false,
      search: '',
      gameMode: this.gameSettings.mode,
      PLAYER_MODES: PLAYER_MODES,
    };
  },
  computed: {
    ...mapGetters([ 'geoJson']),
    ...mapState({
      loadingGeoJson: (state) => state.homeStore.loadingGeoJson,
    }),
    items() {
      return this.maps.map((entry) => entry.properties.name);
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (!val) return;

      this.isLoading = true;

      this.axios
        .get(`https://photon.komoot.io/api/?q=${encodeURI(val)}`)
        .then((res) => {
          if (res.status === 200 && res.data && res.data.features) {
            this.maps = res.data.features.filter(
                (node) => node.properties.osm_type === 'R'
            );
          }
        })
        .catch(() => {})
        .finally(() => (this.isLoading = false));
    },
    geoJson(val) {
      this.addGeoJson(val);
    },
  },
  async mounted() {
    amplitude.track("Choose Map");

    await this.$gmapApiPromiseLazy();
    if (this.geoJson) {
      this.addGeoJson(this.geoJson);
    }

    // TODO: COME BACK TO THIS
    // Update the map to highlight streetview coverage areas
    this.$refs.mapRef.$mapPromise.then((map) => {
      const streetViewLayer = new google.maps.StreetViewCoverageLayer();
      streetViewLayer.setMap(map);
    });
  },
  methods: {
    ...mapActions(['loadPlaceGeoJSON', 'setGeoJson']),
    clickOutside(e) {
      e.stopPropagation();
    },
    reset() {
      this.place = '';
      this.setGeoJson(null);
    },
    addGeoJson(val) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.data.setMap(null);
        let data = new google.maps.Data({
          map: map,
        });
        if (val) data.addGeoJson(val);
        map.data = data;
        if (val && val.bbox) {
          const bounds = new google.maps.LatLngBounds();
          const location1 = new google.maps.LatLng(val.bbox[3], val.bbox[0]);
          const location2 = new google.maps.LatLng(val.bbox[1], val.bbox[2]);
          bounds.extend(location1);
          bounds.extend(location2);
          map.fitBounds(bounds);
          map.setCenter(bounds.getCenter());
        } else {
          map.setZoom(1);
        }
      });
    },
    handleClose() {
      this.reset();
      this.close();
    },
    onNext() {
      this.handleNext();
    }
  }
};
</script>


<style scoped lang="scss">
.search-bar {
  margin: 1rem 0rem;
  border-radius: 4rem;
}
.map-container {
  border-radius: 1rem;
  overflow: hidden;
}
.reset-button {
  box-shadow: none;
  background-color: white !important;
  color: var(--v-primary-base) !important;
  padding: 0.2rem 2rem !important;
  border: 1px solid var(--v-primary-base);
  border-radius: 45px;
  text-transform: none;
  font-size: 1rem;
  margin-right: 1rem;
}
</style>