<template>
    <v-card 
        class="login-card"
        color="white"
    >
        <v-card-title class="headline sign-in-title">
            Sign in to play for free!
        </v-card-title>
        <div id="firebaseui-auth-container"></div>
    </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    loginWithGoogle() {
      // Implementation for Google login
    },
    loginWithEmail() {
      // Implementation for email login
    },
  },
};
</script>

<style scoped>
.sign-in-title {
  font-weight: bold;
  font-family: "League Spartan" !important;
  padding-bottom: 0;;
}
.login-card {
  max-width: 400px;
  margin: auto;
  border-radius: 5%;
  box-shadow: 0px 14px 20px 0px !important;
}
</style>
