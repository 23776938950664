<template>
    <div>
        <v-checkbox
            class="checkbox-input"
            :input-value="value"
            :label="title"
            @change="toggle"
            hide-details
        />
    </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};

</script>

<style lang="scss" scoped>
.checkbox-input {
  margin-top: 0 !important;
}
</style>